












































  import {
    defineComponent,
    toRefs,
    ref,
    computed,
    onMounted,
    onUnmounted,
    PropType,
  } from 'vue'
  import 'vue-custom-scrollbar/dist/vueScrollbar.css'
  import useCurrentInstance from '~/utils/useCurrentInstance'

  export default defineComponent({
    name: 'OneCategoryTree',
    components: {
      VueCustomScrollbar: () => import('vue-custom-scrollbar'),
      OneCategoryTreeItem: () => import('./OneCategoryTreeItem.vue'),
      OneFakeCategoryTreeItem: () =>
        import('~/components/organisms/fake/OneFakeCategoryTreeItem.vue'),
    },
    props: {
      categoriesCount: {
        type: Object as PropType<Record<string, number>>,
        required: true,
        default: () => ({}),
      },
      useAnchors: {
        type: Boolean,
        default: true,
      },
      currentPriceParameter: {
        type: String,
        default: '',
      },
      selectedCategoriesTree: {
        type: Array as PropType<Array<string>>,
        required: true,
        default: () => [],
      },
      selectedCategoryId: {
        type: String,
        required: false,
        default: '',
      },
      showEmptyCategories: {
        type: Boolean,
        required: false,
        default: false,
      },
      onlySelected: {
        type: Boolean,
        required: false,
        default: false,
      },
      loading: {
        type: Boolean,
        required: false,
        default: false,
      },
      hydrationIdle: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    setup(props) {
      const vm = useCurrentInstance()
      const {
        categoriesCount,
        selectedCategoriesTree,
        showEmptyCategories,
        onlySelected,
      } = toRefs(props)
      const mainComponentClass = ref('one-category-tree')
      const sortedMainCategories = computed(() => vm.$store.getters['categories/sortedMainCategories'])
      const getSelectedCategoryId = computed(() => vm.$store.getters['categories/getSelectedCategoryId'])
      const settings = ref<any>({
        minScrollbarLength: 40,
        maxScrollbarLength: 120,
      })
      const isSelected = computed(() => {
        return (categoryId: string): boolean => {
          return (
            selectedCategoriesTree.value &&
            selectedCategoriesTree.value.includes(categoryId)
          )
        }
      })
      const visibleCategories = computed((): Array<string> => {
        if (showEmptyCategories.value) {
          if (onlySelected.value) {
            return sortedMainCategories.value
          }
          return sortedMainCategories.value.filter((categoryId: string) => {
            return isSelected.value(categoryId) || hasItems(categoryId)
          })
        }
        const result = sortedMainCategories.value?.filter((categoryId: string) => hasItems(categoryId))
        return result.length ? result : [getSelectedCategoryId.value]
      })
      const categoryCount = computed(() => {
        return (categoryId: string) => categoriesCount.value[categoryId] || 0
      })
      const onCategorySelected = (categoryId: string) => vm.$emit('category-selected', categoryId)
      const hasItems = (category: string) => categoryCount.value(category) > 0

      onMounted(() => {
        vm.$plugins.onMounted(mainComponentClass.value, this)
      })
      onUnmounted(() => {
        vm.$plugins.onUnmounted(mainComponentClass.value)
      })

      return {
        mainComponentClass,
        settings,
        isSelected,
        visibleCategories,
        categoryCount,
        onCategorySelected,
        hasItems,
      }
    },
  })
